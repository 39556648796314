import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logo2, logoTitle } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center w="100%">
          {/* <CFImage mv="5px" w="90%" src={logo} alt="Aburiya SushiLogo Title" /> */}
          <CFImage mt="10%" w="90%" src={logo2} alt="Aburiya SushiLogo Title" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" zIndex={98}>
          <CFView row justifyBetween alignCenter w="100%" maxWidth="1400px">
            <CFView column justifyStart alignStart pl="4%">
              {/* <CFImage
                h="82px"
                mt="15px"
                src={logo}
                alt="Aburiya SushiKorean Logo Title"
              /> */}
              <CFImage
                h="70px"
                mt="10%"
                src={logo2}
                alt="Aburiya SushiKorean Logo Title"
              />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
